/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Tags from '../components/Tags'
import MarkdownContent from '../components/MarkdownContent'

import '../components/offer.css'

export default function Template({
  data, location, // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter } = data.OfferPage
  return (
    <div>
      <SEO title={frontmatter.name} pathname={location.pathname} keywords={frontmatter.tags} />
      <Layout>
        <div className="offer">
          <div className="offer__page">
            <Img className="offer__page-thumbnail" fluid={frontmatter.picture.childImageSharp.fluid} alt="Index Page" />
            <h1>{frontmatter.name}</h1>
            <MarkdownContent content={frontmatter.description} />
            {data.PricingItemsByLang.edges.length !== 0 && (
              <ul className="offer__page-list">
                <h3 className="offer__page-list-header">Cennik:</h3>
                {data.PricingItemsByLang.edges.map(edge => (
                  <li key={edge.node.id}>
                    <p>{edge.node.frontmatter.description}</p>
                    <p>Cena: {edge.node.frontmatter.price}/{edge.node.frontmatter.quantity}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="separator" />
          {frontmatter.tags && (
          <div className="offer--tags">
            <Tags taggedPages={data.TaggedPages.edges} targetPage={data.OfferPage.id} />
          </div>
          )}
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query OfferInfo($id: String!, $name: String!) {
    OfferPage: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        picture {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        tags
      }
    }
    PricingItemsByLang: allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "pricing-item" }, offer: { eq: $name } } }) {
      edges {
        node {
          id
          frontmatter {
            offer
            quantity
            price
            description
            position
          }
        }
      }
    }
    TaggedPages: allMarkdownRemark(filter: {frontmatter: {templateKey: {regex: "/news-post|offer-page/"}}}) {
      edges {
        node {
          id
          frontmatter {
            tags
          }
        }
      }
    }
  }
  `
